<!-- 合作企业-->

<template>
  <div class="box">
    <div class="title_box">
      <gw-title
        :is-more="false"
        :url="props.params.url"
        :id="props.params.id"
        :title="props.params.title"
      />
    </div>
    <!-- 内容 -->
    <div class="cooperative-enterprise">
      <div class="animation" ref="animation">
        <div class="first_row">
          <div
            class="item-img"
            v-for="(item, index) in data.firstData"
            :key="index"
            @click="delivery(item)"
          >
            <img :src="$img + item.thumb" :alt="index" />
          </div>

          <div class="last-img" v-if="data.firstData.length > 0">
            <img :src="$img + data.firstData[0].thumb" />
          </div>
        </div>

        <div class="second_row">
          <div
            class="item-img"
            v-for="(item, index) in data.lastData"
            :key="index"
            @click="delivery(item)"
          >
            <img :src="$img + item.thumb" :alt="index" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import gwTitle from "@/components/edp/title.vue";
import { watch, reactive } from "vue";
import { useRouter } from "vue-router";

let data = reactive({
  newsData: [],
  firstData: [],
  lastData: [],
});

let props = defineProps({
  params: {
    type: Object,
    default: null,
  },
});

function copy(value) {
  // 此函数的作用是将原来的数据进行三倍填充，用于实现无缝动画
  let mapArr = value.slice();
  let reArr = [];
  let randomValue = 12 - value.length; // 差的位数
  if (randomValue > 0) {
    let handle = shuffleArray(mapArr).slice(0, randomValue);
    reArr = copy([...value, ...handle]);
  } else {
    if (value.length % 2 == 0) {
      let lastArr = [],
        startArr = [];
      for (let k = 0; k < 3; k++) {
        lastArr = lastArr.concat(mapArr.slice(0, mapArr.length / 2));
        startArr = startArr.concat(mapArr.slice(mapArr.length / 2));
      }
      reArr = [...lastArr, ...startArr];
    } else {
      reArr = copy([...value, value[value.length - 1]]);
    }
  }
  return reArr;
}

// 洗牌算法
function shuffleArray(array) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;
  while (currentIndex !== 0) {
    // 从剩余的元素中随机选取一个索引
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    // 将当前元素与随机选取的元素进行交换
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

watch(
  () => props.params,

  () => {
    let arrData = copy(props.params.data);
    data.newsData = arrData;
    data.firstData = arrData.slice(0, arrData.length / 2);
    data.lastData = arrData.slice(arrData.length / 2, arrData.length);
  }
);

let $router = useRouter();
const delivery = ({ id, type_id }) => {
  $router.push({
    name: "detile",
    params: {
      detailsId: id,
      navId: type_id,
    },
  });
};
</script>

<style lang="scss" scoped>
$move-left: -1.5859rem;

// 在pc端展示
@media screen and (min-width: $media) {
  @keyframes move {
    from {
      transform: translateX(-1.5859rem);
    }

    to {
      transform: translateX(calc(-100% + 15rem));
    }
  }

  .box {
    background-color: #fcfcfc;
    padding-top: 0.375rem;
    position: relative;

    .title_box {
      position: relative;
      z-index: 1;
      padding: 0px 1.2969rem;
      margin-top: 0.9453rem;
    }

    .cooperative-enterprise {
      z-index: 1;
      width: 100%;
      height: 2.5625rem;
      margin-top: 0.375rem;
      overflow: hidden;
      position: relative;
      transition: all 0.3s;
      display: flex;

      .animation {
        height: 100%;
        min-width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 0 0 auto;
        /* 不伸缩，固定宽度 */
        transform: translateX(-1.5859rem);
        animation: move 20s linear infinite backwards;

        .first_row {
          min-width: 15rem;
          height: 1.1563rem;
          // background-color: pink;
          display: flex;
          flex-wrap: nowrap;

          .last-img {
            width: 1.5859rem;
            height: 1.1563rem;
            float: left;
            display: flex;
            align-items: center;
            background-color: #fff;
            overflow: hidden;

            > img {
              height: 0.9531rem;
              width: 2.3125rem;
            }
          }
        }

        .second_row {
          min-width: 100%;
          height: 1.1563rem;
          margin-left: 1.7734rem;
          // background-color: red;
        }

        .item-img {
          display: flex;
          align-items: center;
          float: left;
          width: 2.3125rem;
          height: 1.1563rem;
          margin-right: 0.1875rem;
          margin-bottom: 0.25rem;
          background-color: #fff;
          flex: 0 0 auto;

          /* 不伸缩，固定宽度 */
          > img {
            width: 100%;
            height: 0.9531rem;
          }
        }

        & .item-img:last-child {
          margin-right: 0px;
        }

        &:hover {
          animation-play-state: paused;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      background-image: url(~@/assets/edp/images/home/roll.png);
      background-size: 100% 100%;
      top: -0.9375rem;
      z-index: 0;
      display: block;
      height: 8.75rem;
      width: 100%;
      background-color: #fcfcfc;
    }
  }
}

@media screen and (max-width: $media) {
  @keyframes move {
    from {
      ransform: translateX(-27.0667vw);
    }

    to {
      transform: translateX(calc(-100% + 192vw));
    }
  }

  .box {
    background-color: #fcfcfc;
    padding-top: 0px;
    position: relative;

    .title_box {
      position: relative;
      z-index: 1;
      padding: 0px 4.2667vw;
      margin-top: 8.6667vw;
    }

    .cooperative-enterprise {
      z-index: 1;
      width: 100%;
      height: 32.9333vw;
      margin-top: 8.6667vw;
      overflow: hidden;
      position: relative;
      transition: all 0.3s;
      display: flex;

      .animation {
        height: 100%;
        min-width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 0 0 auto;
        /* 不伸缩，固定宽度 */
        transform: translateX(-22.8vw);
        // transform: translateX(calc(-100% + 192vw));
        animation: move 20s linear infinite backwards;
        // transform: translateX(-227px);

        .first_row {
          min-width: 100vw;
          height: 14.8vw;
          display: flex;
          flex-wrap: nowrap;

          .last-img {
            width: 20.1333vw;
            height: 14.8vw;
            float: left;
            display: flex;
            align-items: center;
            background-color: #fff;
            overflow: hidden;

            > img {
              width: 29.8667vw;
              height: 12.2667vw;
            }
          }
        }

        .second_row {
          min-width: 100%;
          height: 14.8vw;
          margin-left: 22.6667vw;
        }

        .item-img {
          display: flex;
          align-items: center;
          float: left;
          width: 29.7333vw;
          height: 100%;
          margin-right: 2.6667vw;
          background-color: #fff;
          flex: 0 0 auto;

          /* 不伸缩，固定宽度 */
          > img {
            width: 100%;
            height: 12.2667vw;
          }
        }

        & .item-img:last-child {
          margin-right: 0px;
        }

        &:hover {
          animation-play-state: paused;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      background-image: url(~@/assets/edp/images/home/roll.png);
      background-size: 100% 100%;
      top: -120px;
      z-index: -1;
      display: block;
      height: 149.3333vw;
      width: 100%;
      background-color: #fcfcfc;
    }
  }
}
</style>
