<template>
  <div class="hot-project">
    <div class="title">
      <gw-title :title="props.params.title" :url="props.params.vue_url" :id="props.params.id" />
    </div>
    <!-- 内容 -->
    <div class="content">
      <template v-for="item in props.params.data" :key="item.id">
        <div class="left" @click="delivery(item.id, item.type_id)"
          v-if="props.params.data[0].id && props.params.data[0].type_id">
          <!-- <div class="logo">
          <div class="icon-box">
            <svg-icon name="sign" class-name="pc" class="pc" />
            <svg-icon
              name="sign"
              class-name="w-9.4667vw h-10.2667vw mobile"
              class="mobile"
            />
          </div>
        </div> -->
          <div class="vertical">
            <img :src="$img + item.thumb" v-if="item.thumb" alt="" />
            <div class="mask-box">
              <div class="title-text">
                <div>
                  <span>{{ item.title_en }}</span>
                </div>
              </div>
              <!-- 只有在pc端才展示 -->
              <div class="pc">
                <div class="text-content">
                  <span>
                    {{ item.description_en }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- <div class="right">
        <div
          class="transverse-item"
          v-for="item in data.list"
          :key="item.id"
          @click="delivery(item.id, item.type_id)"
        >
          <div class="vertical">
            <img :src="$img + item.thumb" alt="" />
            <div class="mask-box">
              <div class="title-text">
                <div>
                  <span>{{ item.title_en }}</span>
                </div>
              </div>
              <div class="text-content pc">
                <span>
                  {{ item.description_en }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import gwTitle from "@/components/edp/title.vue";
import { useRouter } from "vue-router";
import { onMounted, nextTick, reactive, onBeforeUnmount, onUpdated } from "vue";

let props = defineProps({
  params: {
    type: Object,
    default: null,
  },
});

let data = reactive({
  list: [],
});

let timer = null;
const handlerSub = () => {
  if (timer) clearTimeout(timer);
  timer = setTimeout(() => {
    let isMobile = window.matchMedia("(max-width: 1200px)").matches;
    if (props.params) {
      if (isMobile) {
        data.list = props.params.data.slice(1, 3);
      } else {
        data.list = props.params.data.slice(1);
      }
    }
    clearTimeout(timer);
  }, 100);
};

onMounted(() => {
  nextTick(() => {
    window.addEventListener("resize", handlerSub);
  });
});

onUpdated(() => {
  handlerSub();
});

onBeforeUnmount(() => {
  window.removeEventListener("reset", handlerSub);
});

let $router = useRouter();

const delivery = (id, type_id) => {
  if (!id && !type_id) return;
  $router.push({
    name: "detile",
    params: {
      detailsId: id,
      navId: type_id,
    },
  });
};
</script>

<style lang="scss" scoped>
// pc端
@media screen and (min-width: $media) {
  @mixin hover($mask: 1.5938rem, $bottom: -1.0547rem) {
    cursor: pointer;

    .vertical {
      width: 100%;
      height: 100%;
      background-color: #fff;
      position: relative;
      overflow: hidden;

      .mask-box {
        position: absolute;
        width: 100%;
        // height: $mask;
        background: linear-gradient(rgba(255, 189, 165, 0.8) 0%,
            rgba(222, 58, 0, 0.8) 100%);
        bottom: $bottom;
        left: 0px;
        opacity: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: .1563rem;
        // padding-top: 0.1563rem;
        @include transition;

        .title-text {
          width: 100%;
          height: .9375rem;
          padding: .2344rem .2344rem;
          position: relative;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          >div {
            @include ellipsis(2);

            >span {
              font-size: 0.1563rem;
              font-weight: bold;
              color: #ffffff;
              line-height: 0.2734rem;
              text-align: center;
            }
          }

          &::after {
            position: absolute;
            z-index: 99;
            bottom: 0.5208vw;
            left: 50%;
            transform: translateX(-50%);
            content: "";
            display: inline-block;
            width: 1.8594rem;
            height: 0.0156rem;
            background: $hover-color;
            opacity: 0;
            @include transition;
          }
        }

        .text-content {
          margin-top: 0.0938rem;
          height: .7031rem;
          text-align: center;
          padding: 0px 0.2344rem;
          @include ellipsis(3);

          >span {
            font-size: 0.1172rem;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.2188rem;
          }
        }
      }

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 0.0313rem;
        z-index: 20;
        background-color: $hover-color;
        opacity: 0.8;
        @include transition;
      }
    }

    &:hover {
      .vertical {
        >img {
          transform: scale(1.1);
        }

        &::before {
          width: 0%;
        }

        .mask-box {
          bottom: 0px;

          .title-text::after {
            opacity: 1;
          }
        }
      }
    }
  }

  .hot-project {
    height: 100%;
    width: 100%;
    padding: 0.9844rem 1.2969rem 0.9844rem 1.2969rem;

    img {
      width: 100%;
      height: 100%;
      @include transition;
    }

    .content {
      width: 100%;
      height: 100%;
      margin-top: 0.2891rem;
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-gap: .3906rem;
      // flex-wrap: wrap;

      .left {
        position: relative;
        width: 3.875rem;
        height: 4.2266rem;
        @include hover(-1.1719rem, -0.95rem);

        .logo {
          position: absolute;
          left: 0.2969rem;
          top: 0.2891rem;
          z-index: 10;
          opacity: 0.7;

          .icon-box {
            width: 0.5547rem;
            height: 0.6016rem;
          }
        }
      }

      .right {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;

        .transverse-item {
          width: 2.8594rem;
          height: 1.9375rem;
          background-color: #fff;
          @include hover(1.3047rem, -0.7656rem);

          .vertical {
            .mask-box {
              .text-content {
                height: 0.6172rem;
                @include ellipsis(3);

                span {
                  font-size: 0.1172rem;
                  line-height: 0.2188rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

// 移动端
@media screen and (max-width: $media) {
  .hot-project {
    height: 100%;
    width: 100%;
    margin-top: 4.8vw;

    .title {
      margin-bottom: 3.2vw;
    }

    .content {
      width: 100%;
      height: 100%;

      .left {
        position: relative;
        width: 100%;


        .logo {
          position: absolute;
          left: 4.4vw;
          top: 4.9333vw;
          z-index: 10;
          opacity: 0.7;

          .icon-box {
            .svg-box {
              width: 9.4667vw;
              height: 10.2667vw;
            }
          }
        }

        .vertical {
          position: relative;
          margin-bottom: .56rem;

          .mask-box {
            position: absolute;
            bottom: 0px;
            height: 10.6667vw;
            width: 100%;
            background: linear-gradient(rgba(255, 189, 165, 0.8) 0%,
                rgba(222, 58, 0, 0.8) 100%);
            display: flex;
            align-items: center;
            justify-content: center;

            .title-text {
              // height: 3.4667vw;
              padding: 0px 3vw;
              font-size: 2.6667vw;
              font-weight: bold;
              color: #ffffff;
              line-height: 4.6667vw;
              @include ellipsis(2);

            }

            &::before {
              position: absolute;
              bottom: 0px;
              left: 0px;
              content: "";
              display: inline-block;
              width: 100%;
              height: 0.5333vw;
              background: #a6291a;
            }
          }
        }
      }

      .right {
        flex: 1;
        display: flex;
        flex-direction: column;

        .transverse-item {
          width: 100%;
          // height: 42.6667vw;
          background-color: #fff;
          margin-bottom: .56rem;


          .vertical {
            position: relative;

            .mask-box {
              position: absolute;
              bottom: 0px;
              height: 10.6667vw;
              width: 100%;
              background: linear-gradient(rgba(255, 189, 165, 0.8) 0%,
                  rgba(222, 58, 0, 0.8) 100%);
              display: flex;
              align-items: center;
              justify-content: center;

              .title-text {
                height: 3.4667vw;
                font-size: 2.6667vw;
                font-weight: bold;
                color: #ffffff;
                line-height: 4.6667vw;
              }

              &::before {
                position: absolute;
                bottom: 0px;
                left: 0px;
                content: "";
                display: inline-block;
                width: 100%;
                height: 0.5333vw;
                background: #a6291a;
              }
            }
          }
        }

        & .transverse-item:last-child {
          margin-bottom: 0px;
        }
      }
    }

    img {
      width: 100%;
      height: 42.6667vw;
    }
  }
}
</style>
