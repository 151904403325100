<template>
  <div class="news">
    <div class="title">
      <gw-title :title="props.params.title" :url="props.params.vue_url" :id="props.params.id"/>
    </div>
    <div class="left">
      <!-- 大图组件 -->
      <div class="max-img" @click="delivery(props.params?.dataPic[0].id, props.params?.dataPic[0].type_id)" v-if="props.params?.dataPic[0].id && props.params?.dataPic[0].type_id">
        <img :src="$img + props.params?.dataPic[0].thumb" alt="" v-if="props.params?.dataPic[0].thumb"/>
        <div class="mask">
          <div class="title-s">
            <span v-if="props.params.dataPic[0]?.title_en">{{ props.params.dataPic[0]?.title_en }}</span>
          </div>
          <div class="time1">
            <div class="icon-box">
              <svg-icon name="calendar" class-name="w-18px h-20px pc" class="pc"/>
              <svg-icon name="calendar" class-name="w-2.4vw h-2.6667vw mobile" class="mobile"/>
            </div>
            <span v-if="props.params.dataPic[0]?.created_at">{{ props.params.dataPic[0]?.created_at }}</span>
          </div>
        </div>
      </div>
      <!-- 小图组件 -->
      <div class="min-img" @click="delivery(props.params.dataPic[1].id, props.params.dataPic[1].type_id)">
        <div class="img">
          <img :src="$img + props.params.dataPic[1].thumb" alt="" v-if="props.params.dataPic[1].thumb"/>
        </div>

        <div class="content">
          <div class="title-a">
            <span v-if="props.params.dataPic[1].title_en">{{ props.params.dataPic[1].title_en }}</span>
          </div>
          <div class="time">
            <div class="icon-box">
              <svg-icon name="calendar-fill" class-name="w-18px h-20px pc" class="pc"/>
              <svg-icon name="calendar-fill" class-name="w-2.4vw h-2.6667vw mobile" class="mobile"/>
            </div>
            <span v-if="props.params.dataPic[1].created_at">{{ props.params.dataPic[1].created_at }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="text-view">
        <div class="item" v-for="item in props.params.dataList" :key="item.id" @click="delivery(item.id, item.type_id)">
          <div class="t">{{ item.created_at }}</div>
          <div class="title-b">
            <span>{{ item.title_en }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import gwTitle from '@/components/edp/title.vue'
import { useRouter } from 'vue-router'
let props = defineProps({
  params: {
    type: Object
  },
  maxImg:{
    type: Object
  },
  minImg:{
    type:Object
  }
})
// 跳转到其他页面
let $router = useRouter()

const delivery = (id, type_id) => {
  if (!id && !type_id) return
  $router.push({
    name: 'detile',
    params: {
      detailsId: id,
      navId: type_id
    }
  })
}
</script>

<style lang="scss" scoped>
// pc端
@media screen and (min-width: $media) {
  .news {
    width: 100%;
    height: 5.1406rem;
    background-color: transparent;
    margin-top: .9844rem;
    position: relative;

    .title {
      position: absolute;
      right: 0px;
      top: 0px;
      width: calc(100% - 10.5234rem);
      height: .6016rem;
      padding: 0px 1.2969rem 0px .3203rem;
      display: flex;
      align-items: flex-start;
    }

    .left {
      width: 10.5234rem;
      height: 4.7422rem;
      // background: url('@/assets/edp/images/home/news/news1.png') no-repeat;
      background: url(~@/assets/edp/images/home/news/news1.png) no-repeat;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 10;
      display: flex;
      padding-top: .6016rem;
      justify-content: space-between;
      padding-left: 1.2969rem;

      // 大图片
      .max-img {
        width: 6.0391rem;
        height: 3.5rem;
        background-color: transparent;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        .mask {
          position: absolute;
          inset: 0px;
          padding: 0px .2813rem;
          margin-top: 1.8594rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          >.title-s {
            width: 5.5078rem;
            font-size: .2344rem;
            font-weight: bold;
            color: #ffffff;
            line-height: .3906rem;
            text-shadow: 0px .0234rem .0469rem rgba(0, 0, 0, 0.16);
            @include ellipsis(3);
          }

          .time1 {
            margin-top: .1563rem;
            display: flex;
            align-items: center;
            margin-bottom: .1875rem;

            .icon-box {
              width: .1406rem;
              height: .1563rem;
            }

            >span {
              margin-left: .0859rem;
              height: .1641rem;
              font-size: .125rem;
              font-weight: 400;
              color: #ffffff;
              line-height: .1641rem;
            }
          }
        }

        .time {
          margin-top: .1563rem;
          display: flex;
          align-items: center;

          >span {
            margin-left: .0859rem;
            height: .1641rem;
            font-size: .125rem;
            font-weight: 400;
            color: #ffffff;
          }
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        img {
          width: 100%;
          height: 100%;
          @include transition;
        }
      }

      // 小图片
      .min-img {
        width: 2.8672rem;
        height: 3.5rem;
        background: transparent;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        cursor: pointer;

        .img {
          width: 100%;
          height: 2rem;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            @include transition;
          }
        }

        .content {
          flex: 1;
          padding: .1484rem .2031rem 0rem .25rem;
          background: #ffd3bc;

          .title-a {
            width: 2.4141rem;
            height: .8125rem;
            @include ellipsis(3);

            >span {
              font-size: .1641rem;
              font-weight: bold;
              color: #3f3b3a;
              line-height: .2813rem;
              @include transition;
            }
          }

          .time {
            margin-top: .1641rem;
            display: flex;
            align-items: center;

            .icon-box {
              width: .1406rem;
              height: .1563rem;
            }

            span {
              height: .1641rem;
              font-size: .125rem;
              font-weight: 400;
              color: #a17b5a;
              line-height: .1484rem;
              margin-left: .0859rem;
            }
          }
        }

        &:hover {
          img {
            transform: scale(1.1);
          }

          .content>.title-a>span {
            color: $hover-color;
          }
        }
      }
    }

    .right {
      width: 13.5781rem;
      height: 4.5391rem;
      background: url(~@/assets/edp/images/home/news/news2.png) no-repeat;
      background-size: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      right: 0px;
      bottom: 0px;
      z-index: 9;
      padding: .25rem 1.2969rem .3984rem 9.5313rem;

      .text-view {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 2.8594rem;
        height: 100%;
        border-bottom: 4px solid #de3a00;

        .item {
          margin-bottom: .2344rem;
          width: 2.8594rem;
          cursor: pointer;

          .t {
            height: .1641rem;
            font-size: .125rem;
            font-weight: 400;
            color: $time-color;
          }

          .title-b {
            @include ellipsis(3);

            >span {
              font-size: .1719rem;
              font-weight: 500;
              color: #3f3b3a;
              line-height: .2813rem;
              margin-top: .125rem;
            }
          }

          &:hover {
            span {
              color: $hover-color;
            }
          }
        }
      }
    }
  }
}

// 移动端
@media screen and (max-width: $media) {
  .news {
    width: 100%;
    background-color: transparent;
    margin-top: 1.06rem;
    position: relative;

    .title {
      width: 100%;
      height: 6.4vw;
      display: flex;
      align-items: flex-start;
      margin-bottom: 3.2vw;
    }

    .left {
      width: 100%;

      // 大图片
      .max-img {
        width: 91.4667vw;
        height: 48vw;
        background-color: transparent;
        position: relative;
        overflow: hidden;
        margin-bottom: 3.2vw;

        >img {
          width: 100%;
          height: 100%;
        }

        .mask {
          position: absolute;
          inset: 0px;
          padding: 0px 3.7333vw;
          margin-top: 19.4667vw;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          >.title-s {
            width: 82.8vw;
            font-size: 3.7333vw;
            font-weight: bold;
            color: #ffffff;
            line-height: 6.6667vw;
            text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            @include ellipsis(3);
          }

          .time1 {
            margin-top: 2.6667vw;
            display: flex;
            align-items: center;
            margin-bottom: 3.2vw;

            >span {
              display: inline-block;
              margin-left: 1.3333vw;
              height: 2.5333vw;
              font-size: 1.8667vw;
              font-weight: 400;
              color: #ffffff;
              line-height: 2.8vw;
            }

            .icon-box {
              width: 2.4vw;
              height: 2.6667vw;
              .svg-box {
                width: 2.4vw;
                height: 2.6667vw;
              }
            }
          }
        }

        .time {
          margin-top: 20px;
          display: flex;
          align-items: center;

          >span {
            margin-left: 11px;
            height: 21px;
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }

      // 小图片
      .min-img {
        width: 100%;
        height: 30.4vw;
        background: transparent;
        overflow: hidden;
        display: flex;
        margin-bottom: 3.2vw;

        .img {
          width: 48.9333vw;
          height: 100%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .content {
          flex: 1;
          padding: 3.2vw;
          background: #ffd3bc;

          .title-a {
            width: 36.1333vw;
            height: 19.2vw;
            line-height: .7rem;
            @include ellipsis(4);

            >span {
              font-size: 2.8vw;
              font-weight: bold;
              color: #3f3b3a;
              @include transition;
            }
          }

          .time {
            margin-top: 2vw;
            display: flex;
            align-items: center;

            .icon-box {
              .svg-box {
                width: 2.4vw;
                height: 2.66667vw;
              }
            }

            span {
              display: inline-block;
              height: 2.8vw;
              font-size: 2.1333vw;
              font-weight: 400;
              color: #a17b5a;
              line-height: 2.8vw;
              margin-left: 1.4667vw;
            }
          }
        }

        &:hover {
          img {
            transform: scale(1.1);
          }

          .content>.title-a>span {
            color: $hover-color;
          }
        }
      }
    }

    .right {
      width: 100%;
      // height: 581px;
      background-size: 100%;
      background-color: rgba(255, 255, 255, 0.5);

      .text-view {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        border-bottom: 0.5333vw solid #de3a00;
        padding-bottom: 1.7333vw;

        .item {
          margin-bottom: 3.3333vw;
          width: 100%;

          .t {
            height: 2.8vw;
            font-size: 2.1333vw;
            font-weight: 400;
            color: #a17b5a;
          }

          .title-b {
            width: 100%;
            @include ellipsis(2);

            >span {
              font-size: 2.8vw;
              font-weight: 500;
              color: #3f3b3a;
              line-height: 4.8vw;
            }
          }
        }
      }
    }
  }
}
</style>
