<template>
  <div :class="'decorate ' + props.className" v-show="props.title">
    <div class="decorate-left">
      <div class="icon-decorate">
        <svg-icon name="decorate-left" class-name="pc" class="pc"/>
        <svg-icon name="decorate-left" class-name="w-1.3333vw h-6.4vw mobile" class="mobile"/>
      </div>
      <span :style="{ color: props.color }">{{ props.title }}</span>
    </div>

    <div v-show="props.sureMore">
      <div class="decorate-right" v-show="props.isMore" @click="handlerToDelivery">
        <svg-icon name="more" class-name="w-69px h-18px pc" />
        <svg-icon name="more" class-name="w-9.2vw h-2.4vw mobile" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
let props = defineProps({
  title: {
    type: String,
    default: null
  },
  className: {
    type: String,
    default: 'flex-items-center'
  },
  // 只是隐藏与显示
  isMore: {
    type: Boolean,
    default: true
  },
  // 删除元素
  sureMore: {
    type: Boolean,
    default: true
  },
  color: {
    type: String,
    default: '#a6291a'
  },
  url: {
    type: String,
    default: null
  },
  id: {
    type: [String,Number]
  }
})

let $router = useRouter()
let rootPath = '/edps'
const handlerToDelivery = () => {
  let url = `${rootPath}/${ props.url}/${ props.id}`
  if (!props.url) return
  $router.push(url)
 
}
</script>

<style lang="scss" scoped>
// pc端
@media screen and (min-width: $media) {
  .decorate {
    width: 100%;
    height: .375rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    position: relative;
    z-index: 2;

    .decorate-left {
      display: flex;
      align-items: center;

      .icon-decorate {
        width: .0781rem;
        height: .375rem;
      }

      >span {
        display: inline-block;
        height: .2969rem;
        font-size: .2969rem;
        line-height: 0.2734375rem;
        font-weight: 500;
        margin-left: .0781rem;
        vertical-align: middle;
      }
    }

    .decorate-right {
      width: .5391rem;
      height: .1406rem;
    }
  }
}

// 移动端
@media screen and (max-width: $media) {
  .decorate {
    width: 100%;
    height: .96rem;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    position: relative;
    z-index: 2;

    .decorate-left {
      display: flex;
      align-items: center;

      .icon-decorate {
        .svg-box {
          width: .2rem;
          height: 6.4vw;
        }
      }

      >span {
        font-size: .76rem;
        font-weight: 500;
        margin-left: .2rem;
      }
    }

    .decorate-right {
      display: block !important;
      .svg-box {
        width: 1.38rem;
        height: .36rem;
      }
    }
  }
}</style>
