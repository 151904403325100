<!-- 学员天地 -->
<template>
  <div class="box" v-if="props.params.data?.length > 0">
    <div class="gw-title">
      <gw-title :title="props.params.title" :url="props.params.vue_url" :id="props.params.id"/>
    </div>

    <div class="content-box">
      <!-- 介绍 -->
      <div class="left-box" @click="delivery(data.bigImg.id, data.bigImg.type_id)">
        <div class="effect" v-show="action">
          <div class="icon-box">
            <svg-icon name="quotation" class-name="pc" class="pc"/>
            <svg-icon name="quotation" class-name="w-3.8667vw h-3.7333vw mobile" class="mobile"/>
          </div>
          <div class="content-title">
            <span> {{ data.bigImg.description_en }} </span>
          </div>
          <p class="name">{{ data.bigImg.author_en }}</p>
          <p class="source">{{ data.bigImg.title_en }} {{ data.bigImg.created_at }}</p>
          <div class="sign">
            <svg-icon name="sign" class-name="pc" />
          </div>
        </div>
      </div>
      <!-- 浮层图片 -->
      <div class="user-img" v-show="action" @click="delivery(data.bigImg.id, data.bigImg.type_id)">
        <img :src="$img + data.bigImg.thumb" alt="" class="g-img"/>
      </div>
      <div class="right-box">
        <div class="flex flex-justify-between">
          <div class="student" v-for="(item, index) in data.minigraph" :key="index"
            @click="delivery(item.id, item.type_id)">
            <div v-show="action">
              <div class="student-img">
                <img :src="$img + item.thumb" alt="" />
              </div>

              <div class="g-title">
                <div class="describe">
                  <span>{{ item.title_en }}</span>
                </div>

                <div class="time">
                  <span>{{ day(item.created_at).format('YYYY年MM月DD日') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 切换 -->
        <div class="switch pc">
          <button-group @leftClick="handlerLeft" @rightClick="handlerRight" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import gwTitle from '@/components/edp/title.vue'
import buttonGroup from '@/components/edp/buttonGroup.vue'
import { ref, reactive, watch } from 'vue'
import { useRouter } from 'vue-router'
import day from 'dayjs'

let props = defineProps({
  params: {
    type: Object,
    default: null
  }
})

let index = ref(0)

let data = reactive({
  bigImg: {}, // 大图数据
  minigraph: [] // 小图数据
})


watch(
  () => props.params,
  () => {
    data.bigImg = props.params.data[0][0]
    data.minigraph = props.params.data[0].slice(1)
  }
)
let action = ref(true)
function handlerActive(value) {
  if (index.value + value < 0) {
    index.value = props.params.data.length - 1
  } else if (index.value + value >= props.params.data.length) {
    index.value = 0
  } else {
    index.value += value
  }
  action.value = false
  setTimeout(() => {
    action.value = true
  }, 200)
  data.bigImg = props.params.data[index.value][0]
  data.minigraph = props.params.data[index.value].slice(1)
}
// 左切换
const handlerLeft = () => {
  handlerActive(-1)
}
// 右切换

const handlerRight = () => {
  handlerActive(1)
}

let $router = useRouter()
const delivery = (id, type_id) => {
  if (!id && !type_id) return
  $router.push({
    name:"detile",
    params: {
      detailsId: id,
      navId: type_id
    }
  })
}
</script>

<style lang="scss" scoped>
// 在pc端展示
@media screen and (min-width: $media) {
  @keyframes slide-in-right {
    0% {
      transform: translateX(2.3438rem);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .box {
    .gw-title {
      padding: 0px 1.2969rem .3359rem 1.2969rem;
    }

    .content-box {
      width: 100%;
      height: 4.6094rem;
      position: relative;
      background-color: #fcfcfc;

      .left-box {
        width: 7.3516rem;
        height: 100%;
        background: url(~@/assets/edp/images/home/news/news2.png);
        background-size: cover;
        position: absolute;
        z-index: 9;
        padding-left: 1.6797rem;
        padding-top: .3203rem;
        cursor: pointer;

        .effect {
          animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          .icon-box {
            width: .2266rem;
            height: .2188rem;
          }
        }

        .content-title {
          width: 2.4453rem;
          height: 1.3594rem;
          font-size: .1563rem;
          font-weight: 400;
          color: #a17b5a;
          line-height: .2813rem;
          margin-top: .1563rem;
          margin-bottom: .3203rem;
          @include ellipsis(5);
        }

        .name {
          height: .2031rem;
          font-size: .1563rem;
          font-weight: bold;
          color: #3f3b3a;
          line-height: .25rem;
        }

        .source {
          margin-top: .0781rem;
          width: 1.625rem;
          height: .4219rem;
          font-size: .125rem;
          font-weight: normal;
          color: #3f3b3a;
          line-height: .25rem;
        }

        .sign {
          margin-top: .4688rem;
          opacity: 0.7;
          width: .5078rem;
          height: .5469rem;
        }
      }

      .user-img {
        position: absolute;
        top: 0px;
        left: 4.4688rem;
        width: 2.9219rem;
        height: 3.5078rem;
        overflow: hidden;
        z-index: 999;

        .g-img {
          width: 100%;
          height: 100%;
          @include transition;
        }

        &:hover {
          .g-img {
            transform: scale(1.1);
          }
        }
      }

      .right-box {
        position: absolute;
        right: 0px;
        top: 1.0703rem;
        width: 8.2813rem;
        height: 2.9922rem;
        background: url(~@/assets/edp/images/home/news/news1.png);
        z-index: 10;
        padding: 0px 0px .5547rem .9453rem;
        display: flex;
        flex-direction: column;

        .student {
          transform: translateY(-1.0703rem);
          margin-right: .2031rem;
          cursor: pointer;

          >div {
            animation: slide-in-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          }

          .student-img {
            width: 2.9219rem;
            height: 2.1328rem;
            overflow: hidden;

            >img {
              width: 100%;
              height: 100%;
              @include transition;
            }
          }

          .g-title {
            width: 2.9219rem;
            height: .4375rem;
            font-size: .1406rem;
            font-weight: 400;
            color: #ffffff;
            line-height: .25rem;
            text-align: center;
            margin-top: .125rem;
            padding: 0px .1563rem;

            >.describe {
              @include ellipsis(1);
            }
          }

          &:hover {
            img {
              transform: scale(1.1);
            }
          }
        }

        // 切换
        .switch {
          position: absolute;
          bottom: .5547rem;
        }
      }
    }
  }
}

// 在移动端展示
@media screen and (max-width: $media) {
  .box {
    margin-top: .86rem;
    .gw-title {
      margin-bottom: 3.2vw;
    }

    .content-box {
      width: 100%;
      position: relative;
      background-color: #fcfcfc;

      .left-box {
        width: 100%;
        height: 49.0667vw;
        background: url(~@/assets/edp/images/home/news/news2.png);
        background-size: cover;
        z-index: 9;
        padding-top: 4.2667vw;
        padding-left: 3.2vw;
        margin-bottom: 3.2vw;

        .effect {
          .icon-box {
            .svg-box {
              width: 3.8667vw;
              height: 3.7333vw;
            }
          }
        }

        .content-title {
          margin-top: 1.3333vw;
          width: 46.2667vw;
          font-size: 2.4vw;
          font-weight: 400;
          color: #a17b5a;
          line-height: 4.8vw;
          @include ellipsis(4);
        }

        .name {
          font-size: 2.6667vw;
          font-weight: bold;
          color: #3f3b3a;
          line-height: 4.2667vw;
          margin-top: 4.2667vw;
        }

        .source {
          width: 46.6667vw;
          font-size: 2.1333vw;
          color: #3f3b3a;
          line-height: 4.2667vw;
          margin-top: 1.6vw;
          @include ellipsis(2);
        }

        .sign {
          margin-top: 60px;
          opacity: 0.7;
        }
      }

      .user-img {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 100;
        width: 37.0667vw;
        height: 44.8vw;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .right-box {
        width: 100%;
        height: 29.3333vw;
        display: flex;
        flex-direction: column;

        .flex-justify-between {
          justify-content: space-between;
        }

        .student {
          width: 44.1333vw;

          .student-img {
            width: 100%;
            height: 21.6vw;

            >img {
              width: 100%;
              height: 100%;
            }
          }

          .g-title {
            width: 100%;
            background: linear-gradient(320deg, #ffbda5 0%, #e34f1b 78%, #de3a00 100%);
            height: 7.7333vw;
            font-size: 2.4vw;
            font-weight: 400;
            color: #ffffff;
            // line-height: 4.2667vw;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            >.describe {
              font-size: 2.1333vw;
              color: #ffffff;
              // line-height: 7.7333vw;
              @include ellipsis(1);
            }

            >.time {
              font-size: 2.1333vw;
              color: #ffffff;
              line-height: 2.6667vw;
            }
          }
        }

        // 切换
        .switch {
          position: absolute;
          bottom: 71px;
        }
      }
    }
  }
}
</style>
