<template>
  <div class="group">
    <div class="but-left" @click="$emit('leftClick')">
      <svg-icon name="arrow-left" class-name="icon-arrow" />
    </div>
    <div class="but-right" @click="$emit('rightClick')">
      <svg-icon name="arrow-right" />
    </div>
  </div>
</template>

<script setup>
const $emit = defineEmits(['leftClick', 'rightClick'])
</script>

<style lang="scss" scoped>
.group {
  display: flex;
  position: relative;
  z-index: 3;

  >div {
    width: .375rem;
    height: .375rem;
    background: linear-gradient(to right, #bea894, #d1c0b0, #e6d9cd);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .svg-box {
      width: .0938rem !important;
      height: .1563rem;
    }
  }

  .but-left {
    margin-right: .0625rem;

   
  }

  .but-right {
    opacity: 1;
  }
}</style>
