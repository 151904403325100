<template>
  <div class="zh" id="zh">
    <!-- 头部导航栏 -->
    <gw-header />
    <!-- 轮播图组件 -->
    <div class="swiper-wrap">
      <swiper :loop="true" :modules="modules" :pagination="{ clickable: true }" :slides-per-view="1" :autoplay="{ pauseOnMouseEnter: true, delay: 4000 }"
        @swiper="handlerSwiper" @realIndexChange="realIndexChange">
        <swiper-slide class="swiper-item" v-for="item in data.banner" :key="item.url">
          <video :src="$img + item.url" muted preload="none" autoplay :onended="onended" id="video"
            v-if="item.is_vedio == 1" />
          <!-- 轮播图图片 -->
          <img :src="$img + item.url" alt="" v-else />
          <!-- 轮播图视频 -->
          <!-- swiper上层 -->
          <div class="upper-strata">
            <!-- 左边输入框 -->
            <span>{{ item.title }}</span>
            <div class="pc">
              <div class="search">
                <input type="text" placeholder="搜索您最喜欢的课程" @blur="handlerBlur" @focus="handlerFocus"
                  v-model="data.courseSearch" @keypress.enter="handlerCourseSearch" />
                <div class="button" @click="handlerCourseSearch">
                  <svg-icon name="white-search" class-name="w-42px h-42px" color="#fff" />
                </div>
              </div>
            </div>
          </div>
          <!-- logo -->
          <div class="swiper-logo pc">
            <img src="@/assets/edp/images/home/logo-white.png" alt="" />
          </div>
        </swiper-slide>
      </swiper>
      <!-- 报名、电话 -->
      <sign-view />
    </div>
    <main>
      <!-- 新闻资讯 -->
      <gw-news :params="data.newsList" />
      <!-- 热招项目 -->
      <hot-project :params="data.hotProjects" />
      <!-- 学员天地 -->
      <college-world :params="data.studentWorld" />
    </main>
    <!-- 合作企业 -->
    <cooperative-enterprise :params="data.enterprise" />
    <!-- 光影国科 -->
    <guangying-guoke :params="data.Shadow" :cooperate="data.cooperateUnit" />
    <!-- footer -->
    <footer-view :data="data.cooperateUnit" />
  </div>
</template>

<script setup>
import gwHeader from "@/components/edp/ZH/header.vue";
// 新闻资讯
import gwNews from "./component/newsView.vue";
import hotProject from "./component/hotProject.vue";
import collegeWorld from "./component/collegeWorld.vue";
// 合作企业
import cooperativeEnterprise from "./component/cooperativeEnterprise.vue";
// 光影国科
import guangyingGuoke from "./component/guangyingGuoke.vue";
import footerView from "@/components/edp/ZH/footerView.vue";
import signView from "@/components/edp/ZH/signView.vue";
// 引入swiper组件
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
// api
import { apiBannerList, apiContent } from "@/api/home.js";
import { useRouter } from "vue-router";
import { reactive, onMounted, nextTick, ref } from "vue";
// 引入微信的js-sdk
import wx from "weixin-js-sdk";
import axios from "axios";

let modules = reactive([Pagination, Scrollbar, A11y, Autoplay]);

let data = reactive({
  courseSearch: "", // 搜索课程
  banner: [], // 轮播图数据
  newsList: [], // 新闻资讯
  hotProjects: [], // 热招项目
  studentWorld: [], // 学员天地
  enterprise: [], //合作企业
  Shadow: [], // 光影国科
  cooperateUnit: {}, // 共建单位
});
// 点击搜索课程
let $router = useRouter();
const handlerCourseSearch = () => {
  $router.push({
    path: "/edps/hot/417/-3",
    query: {
      q: data.courseSearch,
    },
  });
};

function isMobile() {
  return window.innerWidth <= 1200; // 根据需要调整阈值
}
// 获取轮播图数据
const getBanner = async () => {
  let { code, data: res } = await apiBannerList();
  if (code == 200) {
    if (isMobile()) {
      res = res.filter(item => item.is_vedio == 0)
    }
    data.banner = res;
  }
};
// 获取内容数据
const handlerContent = async () => {
  let res = await apiContent();
  data.newsList = res.data.News; // 新闻资讯
  data.hotProjects = res.data.HotProjects; // 热招项目
  data.studentWorld = res.data.StudentWorld; // 学员天地
  data.enterprise = res.data.CooperativeEnterprise; // 合作企业
  data.Shadow = res.data.Shadow; // 光影国科
  data.cooperateUnit = res.data.CooperateUnit; // 共建单位
  return;
};

// swiper实例
let swiperExample;
// 视频播放结束
const onended = () => {
  nextTick(() => {
    swiperExample.autoplay.start();
  });
};

const handlerBlur = () => {
  swiperExample.autoplay.start();
}

const handlerFocus = () => {
  swiperExample.autoplay.stop()
}

// 轮播图切换
function realIndexChange(t) {
  let video = document.querySelectorAll("#video")
  // console.log("🚀 ~ file: index.vue:155 ~ realIndexChange ~ video:", video)
  try {
    if (data.banner[t.realIndex].is_vedio == 1) {
      // 当轮播到视频视播放视频，然后停止轮播
      nextTick(() => {
        swiperExample.autoplay.stop();
        // video.play && video.play();
        video.forEach(item => {
          if (item) {
            item.play()
          }
        })
      })
    } else if (data.banner[t.realIndex].is_vedio == 0) {
      nextTick(() => {
        swiperExample.autoplay.start();
        // video.pause && video.pause();
        video.forEach(item => {
          if (item) {
            item.pause()
          }
        })
      });
    }
  } catch (e) { }
}
// 开始播放
const handlerSwiper = (objSwiper) => {
  swiperExample = objSwiper;
  nextTick(() => {
    swiperExample.autoplay.stop();
    swiperExample.slideTo(1)
  });
};

onMounted(() => {
  getBanner();
  handlerContent();
  handlerShare();
});

// 微信分享功能
const jsApiList = ["updateAppMessageShareData", "updateTimelineShareData"]; // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
function handlerShare() {
  let source ='https://' + window.location.host;
  axios
    .post("https://xtss.wechat.itxiangtian.cn/api/wechat/getJsSdk", {
      source,
    })
    .then(({ data: res }) => {
      let params = {
        appId: res.data.appId, // 必填，公众号的唯一标识
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.signature, // 必填，签名
        jsApiList: jsApiList, // 必填，需要使用的JS接口列表
      };
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        ...params,
      });
      // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
      wx.ready(function () {
        // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
        wx.updateAppMessageShareData({
          title: "中国科学院大学经济", // 分享标题
          desc: "中国科学院大学经济与管理学院社会服务与高层管理培训中心", // 分享描述
          link: source, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: source + "logo.png", // 分享图标
          success: function () {
            // 设置成功
          },
        });
        // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
        wx.updateTimelineShareData({
          title: "中国科学院大学经济与管理学院社会服务与高层管理培训中心", // 分享标题
          link: source, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: source + "logo.png", // 分享图标
          success: function () {
            // 设置成功
          },
        });
      });
    });
}
</script>

<style lang="scss">
@import "~@/style/reset.scss";
@import "~@/style/varclass.scss";
@media screen and (min-width: $media) {
  .zh {
    width: 15rem;
    height: 100%;
    opacity: 1;
    background: #fff;

    .swiper-wrap {
      width: 100%;
      height: 5.4688rem;
      background-color: #fff;
      position: relative;

      .swiper {
        height: 100%;
        width: 100%;

        // 每一个swiper
        .swiper-item {
          position: relative;

          img {
            width: 100%;
            height: 100%;
          }

          // 上层
          .upper-strata {
            position: absolute;
            left: 2.5rem;
            top: 1.0547rem;
            height: 2.4219rem;
            width: 4.2891rem;
            background-color: transparent;
            z-index: 90;

            >span {
              display: inline-block;
              height: 1.6094rem;
              width: 3.75rem;
              font-size: 0.625rem;
              font-weight: bold;
              color: #ffffff;
              line-height: 0.7813rem;
            }

            .search {
              height: 0.4688rem;
              background: #ffffff;
              border-radius: 0px 0px 0px 0px;
              opacity: 1;
              display: flex;
              margin-top: 0.2656rem;

              input {
                flex: 1;
                border: none;
                outline: none;
                font-size: 0.2031rem;
                font-weight: 400;

                padding: 0.1328rem 0.2344rem;

                &::placeholder {
                  color: rgba(63, 59, 58, 0.44);
                }
              }

              .button {
                width: 0.6016rem;
                height: 0.4688rem;
                background-color: #de3a00;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              }
            }
          }

          .swiper-logo {
            position: absolute;
            right: 1.2969rem;
            bottom: 1.125rem;
            width: 0.7422rem;
            height: 0.8047rem;
            opacity: 0.4;
          }
        }

        video {
          width: 100%;
          height: 100%;
        }

        //小圆点
        .swiper-pagination {
          position: absolute;
          z-index: 999;
          bottom: 0.3438rem;
          display: flex;
          justify-content: center;
          border-radius: 0.0156rem;

          .swiper-pagination-bullet {
            /* 修改每个小圆点的样式 */
            width: 0.125rem;
            height: 0.125rem;
            border: 0.0156rem solid rgba(255, 255, 255, 0.7);
            background-color: transparent;
            opacity: 1;
            margin: 0rem;
            margin-right: 0.1875rem;
          }

          .swiper-pagination-bullet-active {
            width: 0.125rem;
            height: 0.125rem;
            background: #ffffff;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $media) {
  .zh {
    opacity: 1;
    background: #fff;

    main {
      padding: 0px 4.2667vw;
    }

    .swiper-wrap {
      width: 100%;
      height: 36.4vw;
      background-color: #fff;
      position: relative;

      .swiper {
        height: 100%;
        width: 100%;

        // 每一个swiper
        .swiper-item {
          position: relative;

          img {
            width: 100%;
            height: 100%;
          }

          // 上层
          .upper-strata {
            position: absolute;
            left: 10.8vw;
            top: 7.8667vw;
            background-color: transparent;
            z-index: 90;

            >span {
              display: inline-block;
              width: 29.6vw;
              font-size: 4.9333vw;
              font-weight: bold;
              color: #ffffff;
              line-height: 6.1333vw;
            }

            .search {
              height: 60px;
              background: #ffffff;
              border-radius: 0px 0px 0px 0px;
              opacity: 1;
              display: flex;
              margin-top: 34px;
            }
          }

          .swiper-logo {
            position: absolute;
            right: 166px;
            bottom: 144px;
            width: 95px;
            height: 103px;
            opacity: 0.4;
          }
        }

        video {
          width: 100%;
          height: 100%;
        }

        //小圆点
        .swiper-pagination {
          position: absolute;
          z-index: 999;
          bottom: 2.6667vw;
          display: flex;
          justify-content: center;
          border-radius: 2px;

          .swiper-pagination-bullet {
            /* 修改每个小圆点的样式 */
            width: 2.1333vw;
            height: 2.1333vw;
            border: 2px solid rgba(255, 255, 255, 0.7);
            background-color: transparent;
            opacity: 1;
            margin: 0px;
            margin-right: 24px;
          }

          .swiper-pagination-bullet-active {
            width: 2.1333vw;
            height: 2.1333vw;
            background: #ffffff;
          }
        }
      }
    }
  }
}
</style>
