<template>
  <div class="guangying-guoke">
    <div class="title">
      <gw-title
        :is-more="false"
        :title="props.params.title"
        :url="props.params.url"
        :id="props.params.id"
      />
    </div>

    <!-- 视频播放，只有在移动端才展示 -->
    <div class="video-box mobile" v-if="!screenW">
      <div class="video">
        <div style="width: 100%; height: 100%" v-show="toggle">
          <!-- 首页展示图片 -->
          <div class="exhibition" v-if="!isVideo">
            <img :src="$img + data.current.thumb" alt="" />
          </div>
          <!-- 遮罩层 -->
          <div class="mask" v-if="!isMask">
            <svg-icon
              name="play"
              class-name="w-10vw h-10vw"
              @click="handlerPlay"
            />
            <span>{{ data.current.title }}</span>
          </div>

          <div class="play-video" v-show="isVideo">
            <video
              :src="$img + data.current.url"
              controls
              ref="video"
              width="100%"
              height="100%"
              @pause="suspend"
              @play="play"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="guoke-content">
      <!-- 视频播放，只有在pc端才展示 -->
      <div class="pc" v-if="screenW">
        <div class="video-box">
          <div class="but">
            <button-group
              @leftClick="handlerToggle(-1)"
              @rightClick="handlerToggle(1)"
            />
          </div>

          <div class="video">
            <Transition>
              <div style="width: 100%; height: 100%" v-show="toggle">
                <!-- 首页展示图片 -->
                <div class="exhibition" v-if="!isVideo">
                  <img :src="$img + data.current.thumb" alt="" />
                </div>
                <!-- 遮罩层 -->
                <div class="mask" v-if="!isMask" @click="handlerPlay">
                  <svg-icon name="play" />
                  <span>{{ data.current.title }}</span>
                </div>

                <div class="play-video" v-show="isVideo">
                  <video
                    :src="$img + data.current.url"
                    controls
                    ref="video"
                    width="100%"
                    height="100%"
                    @pause="suspend"
                    @play="play"
                  />
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </div>
      <!-- 共建单位 -->
      <div class="jointly-sponsor" v-if="props.cooperate">
        <div class="jointly-sponsor-title">
          <gw-title
            :is-more="false"
            :title="props.cooperate.title"
            :sureMore="false"
          />
        </div>
        <!-- 共建单位 -->
        <div class="hand">
          <template v-for="item in props.cooperate.data" :key="item.id">
            <a :href="item.jump_url" target="_blank">{{ item.title_en }}</a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import gwTitle from "@/components/edp/title.vue";
import buttonGroup from "@/components/edp/buttonGroup.vue";
import { ref, reactive, watch, onMounted, onBeforeMount } from "vue";
// 点击播放视频按钮
let isVideo = ref(false);
// 遮罩层是否展示
let isMask = ref(false);
// 获取视频dom
let video = ref(null);
const handlerPlay = () => {
  isVideo.value = true;
  isMask.value = true;
  video.value && video.value.play();
};
// 暂停视频
const suspend = () => {
  isMask.value = false;
};
// 播放视频
const play = () => {
  isMask.value = true;
};

let props = defineProps({
  params: {
    type: Object,
    default: null,
  },
  cooperate: {
    // 共建单位
    type: Object,
    default: null,
  },
});
// 点击切换
let index = ref(0);
let data = reactive({
  current: {},
});
watch(
  () => props.params,
  () => {
    data.current = props.params.data[0];
  }
);
let toggle = ref(true);
const handlerToggle = (value) => {
  isVideo.value = false;
  isMask.value = false;
  toggle.value = false;
  if (index.value + value < 0) {
    index.value = props.params.data.length - 1;
  } else if (index.value + value >= props.params.data.length) {
    index.value = 0;
  } else {
    index.value += value;
  }
  setTimeout(() => {
    data.current = props.params.data[index.value];
    toggle.value = true;
  }, 300);
};

let screenW = ref(true);
const screenWidth = () => {
  if (window.innerWidth <= 1200) {
    screenW.value = false;
  } else {
    screenW.value = true;
  }
};

onMounted(() => {
  screenWidth();
  window.addEventListener("resize", screenWidth);
});

onBeforeMount(() => {
  window.removeEventListener("reset", screenWidth);
});
</script>

<style lang="scss" scoped>
/* 进入的起点、离开的终点 */
.v-enter,
.v-leave-to {
  transform: scale(0);
  opacity: 0;
}
.v-enter-active,
.v-leave-active {
  transition: all 0.3s linear;
}
/* 进入的终点、离开的起点 */
.v-enter-to,
.v-leave {
  transform: scale(1);
  opacity: 1;
}
// 在pc端展示
@media screen and (min-width: $media) {
  .guangying-guoke {
    margin-top: 1.2188rem;
    .title {
      padding-left: 1.2969rem;
      padding-bottom: 1.4375rem;
    }

    .guoke-content {
      width: 100%;
      min-height: 4.9219rem;
      background: url(~@/assets/edp/images/home/news/jointly.png) no-repeat;
      background-origin: border-box;
      background-size: 100% 100%;
      padding: 0px 1.2969rem;
      position: relative;
      // 视频播放
      .video-box {
        display: flex;
        width: 100%;
        height: 3.8125rem;
        padding-left: 2.2422rem;
        transform: translateY(-1.9219rem);
        .but {
          position: relative;
          z-index: 3;
          padding-top: 1.2813rem;
        }
        .video {
          margin-left: 0.25rem;
          width: 9.1016rem;
          height: 3.8125rem;
          background-color: #fff;
          position: relative;
          overflow: hidden;

          .exhibition {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 9;
            opacity: 1;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .mask {
            position: absolute;
            inset: 0px;
            z-index: 10;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;

            .svg-box {
              width: 1.2188rem;
              height: 0.9141rem;
            }

            > span {
              width: 2.3438rem;
              height: 0.5469rem;
              font-size: 0.3906rem;
              font-weight: 400;
              color: #ffffff;
              line-height: 0.625rem;
              margin-left: 0.6328rem;
            }
          }

          .play-video {
            width: 100%;
            height: 100%;
            > video {
              width: 100%;
              height: 100%;
              &::-webkit-media-controls-start-playback-button {
                display: block;
                font-size: initial;
              }
            }
          }
        }
      }

      // 共建单位
      .jointly-sponsor {
        position: absolute;
        top: 2.6016rem;
        width: 12.4063rem;
        .hand {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;
          // flex-direction: column;
          margin-top: 0.3047rem;
            a {
              color: #fff;
              font-size: 0.2188rem;
              transition: all 0.3s;
              margin-right: .2734rem;
              margin-bottom: .2734rem;
              &:hover {
                transform: scale(1.1);
            }
          }

          & div:first-child {
            margin-left: 0px;
          }
        }
      }
    }
  }
}

// 在移动端展示
@media screen and (max-width: $media) {
  .guangying-guoke {
    position: relative;
    z-index: 600;
    margin-top: 1rem;
    min-height: 84.6667vw;
    .title {
      padding: 0px 4.2667vw;
      margin-bottom: 4.2667vw;
    }

    // 视频播放
    .video-box {
      display: flex;
      width: 100%;
      height: 41.8667vw;
      .video {
        width: 100%;
        height: 100%;
        background-color: #fff;
        position: relative;
        overflow: hidden;

        .exhibition {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 9;
          opacity: 1;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .mask {
          position: absolute;
          inset: 0px;
          z-index: 10;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          display: flex;
          align-items: center;
          justify-content: center;
          > span {
            font-size: 4.1333vw;
            font-weight: 400;
            color: #ffffff;
            line-height: 1.6rem;
            margin-left: .6rem;
          }

          .svg-box {
            width: 10vw;
            height: 10vw;
          }
        }

        .play-video {
          width: 100%;
          height: 100%;
          > video {
            width: 100%;
            height: 100%;
            &::-webkit-media-controls-start-playback-button {
              display: block;
              font-size: initial;
            }
          }
        }
      }
    }

    .guoke-content {
      width: 100%;
      min-height: 42.8vw;
      background: url(~@/assets/edp/images/home/news/jointly.png) no-repeat;
      background-origin: border-box;
      background-size: cover;
      padding: 6.4vw 4.2667vw;
      z-index: 10;

      // 共建单位
      .jointly-sponsor {
        width: 100%;
        display: flex;

        .jointly-sponsor-title {
          width: 23.7333vw;
        }
        .hand {
          height: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 0px;
          margin-left: 1.8rem;
          overflow: auto;
          padding-right: .8rem;
            a {
              color: #fff;
              font-size: 2.6667vw;
              font-weight: 400;
              color: #ffffff;
              line-height: .6rem;
            }
        }
      }
    }
  }
}
</style>
